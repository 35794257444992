@import styles/sass/abstracts

.label
  margin-bottom: 4px !important
  color: $primary-middle
  +font(12px, 120%, 600)

.submit
  width: 100%
  margin-top: 22.5px
