@import 'styles/sass/abstracts'
  
.page
  background: $grey
  padding-top: $header-height
  +transition(padding-top .2s)
  +res-md-max
    padding-top: $header-height-mobile

\:global(.toolbar)
  ~ .page
    +res-lg-min
      padding-top: #{$header-height + $toolbar-height}
    +res-md-max
      padding-top: #{$header-height-mobile + $toolbar-height}

\:global(body.w-scrolled)
  .page
    +res-md-max
      padding-top: $header-height-mobile-collapsed

\:global(body.w-scrolled)
  \:global(.toolbar)
    ~ .page
      +res-md-max
        padding-top: #{$header-height-mobile-collapsed + $toolbar-height}
