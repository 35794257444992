@import styles/sass/abstracts

.prodottoCard
  display: block
  text-align: center
  position: relative
  padding-top: 5px // per compensare il translate negli slider che sono in overflow: hidden
  padding-bottom: 24px
  text-align: center
  z-index: 0
  //size
  &--sm
    .imageBox
      max-width: 164px

.imageBox
  position: relative
  max-width: 176px
  margin: 0 auto
  transition: transform .2s 
  &:hover
    +translateY(-5px)
  &--no-animation
    &:hover
      +translateY(0px)

.content
  margin: 0 -13px

.title
  +typoTextSm
  font-weight: $fw-bold
  color: $primary-default
  margin-bottom: 10px
  word-break: break-word
  max-width: 100%
  &:hover
    text-decoration: underline

.shape
  position: absolute
  bottom: 0
  max-width: 160px
  margin: 0 auto
  left: 5%
  right: 5%
  z-index: -1
  &::after
    content: ""
    position: absolute
    margin: 0 auto
    bottom: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 0
    padding-bottom: 100%
    border-radius: 100%
    background: $neutral-1
    +res-sm-max
      width: auto
      height: auto
