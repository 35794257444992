@import 'styles/sass/abstracts'

.label
  color: $primary-middle
  +font(12px, 120%, 600)

.submit
  width: 100%
.thankyouMessageText
  +typoH2Serif
  margin-bottom: 20px
