@import styles/sass/abstracts

.layout
  +res-lg-min
    display: flex

.linksMobile
  +res-md-min
    display: none

.linksDesktop
  +res-lg-min 
    flex: 1
    > *
      &:not(:last-child)
        margin-bottom: 20px
  +res-md-only
    display: flex
  +res-sm-max
    display: none

.linksDesktopItem
  +res-md-only
    flex: 1 0 0
    &:not(:last-child)
      margin-right: 16px
    > *
      margin: 0 auto

.title
  text-align: center
  +typoH2Serif
  margin-bottom: 30px
  +res-sm-max
    +typoH1SerifMobile

.strumenti
  +res-lg-min
    margin-right: 16px
    width: 40%
  +res-md-max
    margin-bottom: 30px
    
.strumentiLinkUtili
  \:global(.swiper-container)
    margin: 0px #{$wcont-padding * -1} // 16 del panel + 20 del topMenu con sfondo grigino
    padding: 0 $wcont-padding 24px // paginazione bottom



