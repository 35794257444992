@import styles/sass/abstracts

.banner
  overflow: hidden
  height: $header-banner-height-mobile
  padding: 0 $wcont-padding
  display: flex
  align-items: center
  justify-content: space-between
  background: $accent-default
  +transition(height .2s)
  > *
    &:not(:last-child)
      margin-right: 12px
  +res-lg-min
    display: none

.bannerLabel
  color: $white
  +typoBase(12px, 16.8px, null, $fw-semibold)
  +res-lg-min
    display: none

.header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 3000
  +res-lg-min
    box-shadow: $shadow-md

.top
  height: $header-top-height
  background: $neutral-2
  +res-md-max
    display: none

.main
  position: relative
  height: $header-main-height
  background: $white
  +res-md-max
    height: $header-main-height-mobile

.mainContent
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  padding: 20px 100px
  height: $header-main-height
  //+res-lg-min
  //  > *
  //    &:not(:last-child)
  //      margin-right: 24px
  +res-md-max
    align-items: center
    height: $header-main-height-mobile
    padding: 16px 20px

.logoWrap
  @media(max-width: $res-lg-min)
    margin-left: auto
  +res-lg-min
    width: 180px
    text-align: center
  +res-md-max
    display: flex
    align-items: center
    > * 
      &:not(:last-child)
        margin-right: 6px

.logo
  display: block
  svg
    display: block
    color: $primary-default
    +res-lg-min
      margin: 0 auto
      width: 106px
      height: 78px
      &.logoMobile
        display: none
    +res-md-max
      width: 58px
      height: 40px
      &.logoDesktop
        display: none
    
.payoff
  svg
    display: block
    max-width: 100%
    margin: 0 auto

.areaRiservataMobileButton
  margin-left: auto
  +res-lg-min
    display: none

.navMobileButton
  cursor: pointer
  +res-lg-min
    display: none
  svg
    +style-icon(24px, currentColor)

.nav, .navDropdown
  +res-md-max
    display: none
    position: fixed
    top: $header-height-mobile
    bottom: 0
    right: 0
    width: 100%
    max-width: 368px
    padding: 16px
    +transition(#{"transform .2s, top .2s"})
    +translateX(100%)
    background: $white

.nav
  flex: 1
  align-self: stretch

.searchBarContainer
  +res-lg-min
    position: relative
    width: 40px
    height: 100%

.searchBar
  position: absolute
  top: 0
  height: 100%
  right: 0
  overflow: hidden
  background: $white
  z-index: 101
  +res-lg-min
    width: 40px
    padding-top: 13px
    +transition(width .2s)
    &--desktop-open
      width: #{$wcont-width-md - 2*24px - 2*$wcont-padding - 180px - 195px}
      @media (max-width: $wcont-width-md)
        width: calc(100vw - #{2*24px + 2*$wcont-padding + 180px + 195px})
  +res-md-max
    position: absolute
    left: 0
    right: 0
    top: 100%
    height: $header-search-height-mobile
    padding: 0 $wcont-padding
    +transition(height .2s)
    box-shadow: $shadow-md

.searchTrigger
  position: absolute
  top: calc(50% - 20px)
  right: 0
  padding: 8px
  cursor: pointer
  z-index: 102
  svg
    +style-icon(24px, currentColor)
  &--desktop-open
    padding: 12px
    svg
      +size-icon(16px)
  +res-md-max
    display: none

.stateButton
  margin-left: auto
  align-self: center
  width: 106px // serve per il calcolo della larghezza della search
  height: 48px
  +res-md-max
    display: none

.loginButton
  @extend .stateButton

.profileButton
  @extend .stateButton
  background-color: $white
  color: $accent-default
.profileButton:hover
  @extend .stateButton
  color: $white

.mobileButton
  color: $black

.navMobile
  position: fixed
  top: 0
  bottom: 0
  left: 0
  width: 80%
  max-width: 600px
  +translateX(-100%)
  z-index: 9000
  box-shadow: $shadow-md
  +transition(#{"transform .2s, top .2s"})
  +res-lg-min
    display: none
  &--open
    +translateX(0)

\:global(body.w-scrolled)
  .banner
    height: 0
  .main
    +res-md-max
      box-shadow: $shadow-md
  .searchBar
    +res-md-max
      height: 0
  .navMobile
    top: 0
  \:global(.toolbar ~ .page)
    .navMobile
      top: #{$toolbar-height}

\:global(.toolbar ~ .page)
  .header
    top: $toolbar-height
  .navMobile
    top: #{$toolbar-height}

.desktop
  +res-md-max
    display: none !important

.mobile
  +res-lg-min
    display: none

.leftContent
  margin-right: auto
.centerContent
  display: flex
  flex-direction: row
  align-items: flex-start
  justify-content: center
  margin: auto
  width: 50%
.rightContent
  margin-left: auto
  +res-sm-max
    display: none

.menu
  @extend .desktop
  display: flex
  flex-direction: row
  align-items: flex-start
  justify-content: center
  padding: 0
  gap: 60px
  .menuItem
    +font(18, 25px, 600)

.logoMobile
  max-height: 40px