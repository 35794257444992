@import styles/sass/abstracts

.footer
  color: $white
  background: $primary-corporate
  +typoTextSm
  margin-top: 54px
  padding: 50px 100px 30px 100px
  +res-md-max
    padding: 50px 0 10px 0
  box-shadow: 0 -1px 0 rgba(0,0,0,0.1)

.top
  margin-bottom: 150px
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-start
  gap: $gap-lg-2
  +res-md-max
    flex-direction: column
    margin: 0 20px
  +res-sm-max
    flex-wrap: wrap
    margin-bottom: 40px

.topMenu
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: $gap-md

.menuItems
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: $gap-md
  flex: none
  flex-wrap: wrap
  order: 1
  flex-grow: 0
  justify-content: flex-end
  width: 100%
  +res-md-max
    justify-content: flex-start

.storeMenuItem
  width: 150px
  height: 72.84px
  background-color: $white
  box-shadow: 0 2px 8px rgba(0,0,0,0.1)
  border-radius: 8px
  +res-md-max
    width: 162.5px

.storeItem
  display: flex
  justify-content: center
  height: 100%
  align-items: center

.socialItem
  margin-top: 20px
  svg
    +style-icon(24px, $white)

.menuLabel
  font-weight: 700
  font-size: 18px
  line-height: 140%


.containerMenuFooter
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  gap: $gap-lg-2
  +res-md-max
    margin: 32px 20px 0 20px
    flex-direction: column-reverse
    align-items: flex-start

.corporateMenuFooter
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-bottom: 40px
  +res-md-max
    margin: 32px 20px 0 20px
    flex-direction: column
    align-items: flex-start
    flex-wrap: wrap
    max-height: 160px
  +res-sm-max
    max-height: 400px

.corporateMenuGroup
  display: flex
  flex-direction: column  
  align-items: flex-start

.corporateItem
  +font(14, 19, 600)
  border-bottom: 1px $white solid
  margin-bottom: 12px
  +res-md-max
    margin-bottom: 20px

.bCorpDesktop
  +res-md-max
    display: none!important

.bCorpMobile
  margin-left: auto
  +res-lg-min
    display: none!important

.info
  +font(12, 15, 400)

.menu
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: $gap-md
  +res-md-max
    flex-direction: column
  a:link
    border-bottom: 1px $white solid

.menuItem
  text-align: center
  +font(14, 19, 600)

.menuSeparator
  background: $border-color

.socialMenu
  flex-direction: row
  +res-md-max
    width: 100%