@import styles/sass/abstracts

.genericCard
  display: flex
  flex-direction: column
  text-align: center
  justify-content: center
  padding: 30px 16px
  height: 100%
  +border-radius(12px)
  background-color: $neutral-1
  // Variant
  &--white
    background-color: white
  +res-sm-max
    padding: 30px 16px

  .icon
    margin-bottom: 20px
    svg
      text-align: center
      +size-icon(80px)
      +res-sm-max
          +size-icon(60px)
  .title
    +typoH4
    margin-bottom: 20px
    +res-sm-max
      +typoH5

  .description
    +typoTextMd
    +customContent
    +transition(color .2s)
    margin-bottom: 30px

  .list
    display: flex
    justify-content: center
    > *
      &:first-child
          margin-right: 8px

  &--sm
    .title
      +typoH5
    
  &--icon-sm
    .icon
      svg
        +size-icon(60px)
  
  &--shadow, &--shadow--cta-primary
    box-shadow: $shadow-md
  



    

