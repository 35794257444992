@import styles/sass/abstracts

.overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba($primary-dark, .6)
  z-index: -1
  overflow: auto

.searchResultsDropdown
  position: absolute
  top: 100%
  left: 0
  right: 0
  max-height: 0
  background: $white
  z-index: 3000
  overflow: auto
  +scrollbar-style
  +res-md-max
    top: calc(100% + $header-search-height-mobile)
  &--open
    max-height: calc(100vh - $header-height)
    +res-sm-max
      max-height: calc(100vh - $header-height-mobile)

.layout
  +res-lg-min
    padding-left: 204px
  +res-lg-min
    padding: 30px 0 30px 204px
    display: flex
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 20px
  +res-md-max
    padding: 20px 0
    > *
      &:not(:last-child)
        margin-bottom: 30px


.title
  +typoTextXs
  font-weight: $fw-semibold
  letter-spacing: .02em 
  text-transform: uppercase
  color: $primary-light

.simpleItem
  display: block
  margin-top: 19px
  +typoTextMd
  font-weight: $fw-bold
  color: $primary-default
  span
    color: $primary-light

.list
  > *
    &:not(:last-child)
      margin-bottom: 20px

\:global(body.w-scrolled)
  .overlay, .searchResultsDropdown
    display: none

\:global(.toolbar ~ .page)
  .searchResultsDropdown
    &--open
      max-height: calc(100vh - $header-height - $toolbar-height)
      +res-sm-max
        max-height: calc(100vh - $header-height-mobile - $toolbar-height)