@import styles/sass/abstracts

.title
  text-align: center
  +typoH3
  margin-bottom: 20px

.description
  text-align: center
  +typoTextSm
  +customContent

.subLink
  text-align: center
  +typoTextSm
  +customContent

.subDescription
    margin-top: 20px

.myOptinCard
  +whiteBoxShadow
  padding: 30px
  align-content: center

.modalContainer
  display: flex
  flex-direction: column
  align-items: center
  gap: $gap-lg-2

.separator
  +res-lg-min
    display: block
