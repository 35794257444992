@import 'styles/sass/abstracts'

.buttonForm
  border: 1.5px solid #00559D
  width: 160px
  height: 51px
  padding-right: 16px

.label
  color: $primary-middle

.buttons
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: $gap-sm-1
