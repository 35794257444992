@import styles/sass/abstracts

.head
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  margin-bottom: 16px

.container
  display: flex
  padding-bottom: 22px

.title
  +font(20, 22, 700)
  +typoH5
  min-height: 30px

.description
  margin-bottom: 24px
  +res-sm-max
    margin-bottom: 16px

.editContainer
  height: 80%
  margin-left: auto
  border-bottom: 1px solid $accent-default
  .label
    +font(16, 19.2, 600)
.svg
 width: 85%

.editButton
  padding: 0 !important

.buttons
  display: flex
  justify-content: flex-start
  > *
    &:not(:last-child)
      margin-right: 20px

