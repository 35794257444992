@import styles/sass/abstracts

.container
  padding: 30px
  +res-sm-max
    padding: 20px

.header
  display: flex
  flex-direction: column
  text-align: center
  justify-content: center
  padding-bottom: 40px
  .title
    +typoTitleMd

.menu
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: $gap-sm-2
  .item
    flex-grow: 1
    width: calc(33.33% - 16px)
    padding: 30px 24px
    background-color: unset
    color: $accent-dark
    border: 1px solid rgba(36, 52, 105, 0.4)
    border-radius: 8px
    +res-sm-max
      width: calc(50% - 16px)
  .item:hover
    color: $white
  .item--active
    color: $white
    background: $accent-dark

.b2xReason
  display: flex
  justify-content: space-between
  // align-items: center
  margin-top: 40px
  +res-sm-max
    flex-direction: column
    align-items: center
    margin-top: 36px

.b2xSite
  width: 100%
  max-width: 388px
  +res-md-min
    margin-bottom: 0
    margin-right: 20px

.b2xCta
  +font(16, 19, 600)
  +res-md-min
    align-self: flex-end