@import styles/sass/abstracts

.privacy_title
  color: $secondary-dark
  +font(14px, 120%, 700)
  padding: 20px 0 10px 8px

.buttons
  width: 100%
  padding-top: 20px
  .submit
    width: 100%

.optins
  display: flex
  flex-direction: column
  width: 100%

.fieldMarketing
  > *
    flex-direction: column

.preferenzeTrigger
  align-self: flex-start
  margin-left: 10px
  text-decoration: underline

.preferenzeContainer
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 10px
  padding: 20px 10px
  >*
    flex: 49%
  +res-md-max
    >*
      flex: 100%

.optinBrandCard
  width: 100%
  background-color: white
  padding: 0 16px 0 10px
  border-radius: 4px
  display: flex
  justify-content: space-between
  align-items: center
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%)

  .optinNameImageContainer
    display: flex
    align-items: center

    img
      padding-right: 10px

    .name
      color: $primary-dark
      font-size: 16px
      padding-left: 10px

.optinBrandInformativa
  display: block
  +font(12, 14, 400)
  text-decoration: underline
  padding-left: 10px