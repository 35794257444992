@import styles/sass/abstracts

.container
  padding: 30px 14px
  +res-sm-max
    padding: 20px 4px

.header
  display: flex
  flex-direction: column
  text-align: center
  justify-content: center
  padding-bottom: 40px
  .title
    +typoTitleMd

.menu
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: $gap-sm-2
  .item
    flex-grow: 1
    width: calc(33.33% - 16px)
    padding: 30px 24px
    background-color: unset
    color: $accent-dark
    border: 1px solid rgba(36, 52, 105, 0.4)
    border-radius: 8px
    +res-sm-max
      width: calc(50% - 16px)
  .item
    height: 104px
    +res-sm-max
      height: 82px
      +font(16, 22, 700)
  .item:hover
    color: $white
