@import styles/sass/abstracts

.myAccountMenu
  height: 100%
  +res-md-max
    height: 56px
    
  \:global(.swiper-container)
    width: 100%
    height: 100%

  \:global(.swiper-slide)
    position: relative
    width: auto
    &:not(:last-child)
      +res-sm-max
        margin-right: 17px
        &::after
          content: ""
          position: absolute
          top: calc(50% - 20px)
          right: -8px
          height: 40px
          width: 1px
          background-color: $neutral-4

.list
  height: 100%
  display: flex
  flex-direction: column
  //min-height: calc(100vh - #{$header-height + 60px}) // 60px = 40px di padding superiore della sidebar + 20px custom di distacco dal fondo
  > *
    &:not(:last-child)
      margin-bottom: 16px
  +res-md-max
    //display: none

.blockList
  +res-md-max
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 0 16px
    gap: 8px
    > *
      width: 50%
    :not(:last-child)
      margin-right: 12px

.blockTitle
  +typoTextSm
  font-weight: $fw-black
  text-transform: uppercase
  color: $primary-middle
  margin-bottom: 16px

.blockEnd
  margin-top: auto
  +res-md-max
    display: none

.logout
  margin-top: auto
  .myAccountMenuItem
    background-color: $grey !important

.slider
  +res-lg-min
    display: none

.item
  display: block  

.myAccountMenuItem
  background-color: white
  padding: 20px
  border-radius: 4px
  align-items: center
  margin-bottom: 10px
  display: flex
  +res-lg-min
    gap: 16px
  &--active
    color: white
    background-color: $neutral-4
    cursor: default
    .label
      font-weight: 900

.icon, .iconRight
  +style-icon(24px, currentColor)

.label
  +typoTextMd
  font-weight: 600
  
\:global(.toolbar ~ .page)
  .list
    +res-lg-min
      min-height: calc(100vh - #{$header-height + $toolbar-height + 60px})