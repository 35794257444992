@import 'styles/sass/abstracts'

.desktop
  display: flex
  flex-direction: row
  gap: $gap-sm-2
  +res-sm-max
    display: none
  .container
    display: flex
    flex-direction: column
    padding: 30px
    height: 100%
    +whiteBoxShadow
    .header
      margin-bottom: 40px
      text-align: center
      .title
        +typoTitleMd
.mobile
  display: flex
  flex-direction: column
  background-color: white
  padding: 32px 16px
  +res-md-min
    display: none
  .mobileButtons
    padding-top: 10px

.steps
  display: flex
  flex-direction: column
  gap: $gap-md
  max-width: 800px
  margin: auto

.stepbar
  display: flex
  flex-direction: row
  background-color: $white

.box
  background-color: $white
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1)
  border-radius: 4px

.submit
  +font(16, 22, 600)