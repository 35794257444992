@import styles/sass/abstracts

.emptyMessageBox
  padding: 40px
  background: $neutral-1
  color: $primary-default
  border-radius: 16px
  text-align: center
  > *
    &:not(:last-child)
      margin-bottom: 16px

.title
  +typoH4

.description
  +typoTextLg
