@import styles/sass/abstracts

.searchResultItem
  display: flex
  +res-md-min
    align-items: center
  +res-sm-max
    align-items: flex-start
  > *
    &:not(:last-child)
      margin-right: 20px
      +res-sm-max
        margin-right: 16px

.imageBox
  text-align: center
  width: 120px
  +res-sm-max
    width: 80px
  img
    display: block
  &--prodotto
    position: relative
    img
      position: relative
      margin: 0 auto
      z-index: 1
    &::before
      content: ""
      position: absolute
      bottom: -8px
      left: 0
      right: 0
      max-width: 80px
      height: 80px
      margin: 0 auto
      border-radius: 50%
      background: $neutral-1
      z-index: 0
  &--articolo, &--settimana
    border-radius: 12px
    overflow: hidden
    height: 100px
    box-shadow: $shadow-md
    +res-sm-max
      height: 80px
  &--articolo
    > *
      height: 100%!important
  &--settimana
    +typoBase(40px, 140%, .01em, $fw-bold, $font-secondary)
    background: $neutral-1
    color: rgba($primary-default, .5)
    display: flex
    align-items: center
    justify-content: center

  ~ .main // serve un contro preciso per le ellipsis
    max-width: calc(100% - 140px)
    +res-sm-max
      max-width: calc(100% - 96px)

.main
  flex: 1
  max-width: 100%
  
.content
  > *
    &:not(:last-child)
      margin-bottom: 8px

.type
  +typoTextXs
  font-weight: $fw-black
  text-transform: uppercase
  color: $primary-light
  +res-sm-max
    display: none

.title
  +typoTextMd
  font-weight: $fw-black

.description
  +typoTextSm

.cta
  align-self: flex-end
  margin-bottom: -10px // compensa il padding

.searchResultItem
  &--sm
    .imageBox
      width: 100px
      +res-sm-max
        width: 75px
    .type
      +typoTextSm
    .title
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .description
      +multiline-ellipsis(2, 20px)
      
  &--md
    .main
      +res-md-min
        display: flex
        > *
          &:not(:last-child)
            margin-right: 20px
    .description
      +multiline-ellipsis(2, 20px)
    .cta
      +res-md-min
        min-width: 100px
        margin-left: auto
