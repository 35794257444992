@import styles/sass/abstracts

.viewItem
  width: 100%
  padding-bottom: 20px

.buttonForm
  border: 1.5px solid $accent-default
  width: 160px
  height: 51px
  padding-right: 16px

.label
  color: $primary-middle

.container
  +res-lg-min
    justify-content: center
  +res-md-max
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: $gap-sm-2

.separator
  +res-md-max
    margin-bottom: 10px

.buttons
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: $gap-sm-1
