@import 'styles/sass/abstracts'

.error
  text-align: center
  color: $error-default

.helpers
  +typoTextSm
  display: flex
  align-items: center
  justify-content: space-between
  margin: 4px 0 20px
  a
    font-weight: 600
    color: $accent-default

.forgotPassword
  margin-left: auto
  +font(14, 16.8, 600)

.resendActivation
  display: block
  +typoTextSm
  font-weight: 600
  color: $accent-default
  text-align: right
  margin-bottom: 20px

.submit
  width: 100%
  +font(16, 22, 500)

.registration
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  text-align: center
  gap: 5px
  padding-top: 20px
  +font(14, 16.8, 400)
