@import styles/sass/abstracts

.container
  padding: 30px
  +res-sm-max
    padding: 20px 0

.header
  display: flex
  flex-direction: column
  text-align: center
  justify-content: center
  padding-bottom: 40px
  .title
    +typoTitleMd

.buttons
  margin-top: 40px
  width: 100%
  .submit
    width: 100%
    
.privacyTitle
    +typoDescriptionSm
    margin-bottom: 13px