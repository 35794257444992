@import styles/sass/abstracts

.myAccountDatiView
  +res-lg-min
    display: flex
    flex-wrap: wrap
  margin: 0 -16px
  > *
    width: 33.33%
    padding: 0 16px 0 16px
    +res-sm-max
      width: 50%
      &:not(:last-child)
        margin-bottom: 15px
    &:nth-child(2)
      +res-md-min
        margin-right: 33.33%
    
    &:nth-child(1), &:nth-child(2)
      +res-md-min
        margin-bottom: 15px
