@import styles/sass/abstracts


.confirmButton
  background-color: $white
  color: $accent-default
.confirmButton:hover
  color: $white

.cancelButton:hover
  background-color: $white
  color: $white

.title
  font-style: normal
  font-weight: 700
  font-size: 24px
  line-height: 110%
  text-align: center

.description
  font-style: normal
  font-size: 16px
  line-height: 140%
  text-align: center

.subDescription
  margin-top: 30px
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 140%
  text-align: center

.subLink
  color: $accent-default
  text-decoration: underline
  +typoTextSm
  +customContent
.subLink:hover
  text-decoration: underline
