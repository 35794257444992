@import styles/sass/abstracts

.myAccountSection
  background: $white
  padding: 20px 30px 30px
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)
  border-radius: 4px
  &:not(:last-child)
    margin-bottom: 50px
  &--locked
    .content
      opacity: .5

.head
  display: flex
  align-items: center
  justify-content: space-between
  > *
    &:not(:last-child)
      margin-right: 30px

.title
  display: flex
  align-items: center
  +typoH4
  +res-sm-max
    +typoH5
  &__icon
    margin-right: 8px
    svg
      +style-icon(32px, currentColor)

.description
  +typoTextSm 
  +customContent
  margin-top: 10px
  +res-sm-max
    margin-top: 16px

.body
  position: relative
  margin-top: 20px

.lockedOverlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1
  display: flex
  justify-content: center
  align-items: center

.lockedModal
  background-color: $white
  padding: 20px
  +border-radius(12px)
  +res-md-min
    display: inline-flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 10px
  +res-sm-max
    text-align: center

.lockedModalIcon
  svg
    +style-icon(40px, currentColor)
  +res-sm-max
    margin-bottom: 10px
    svg
      margin: 0 auto

.lockedModalLabel
  +typoTextSm
  font-weight: 600
