@import styles/sass/abstracts

.container
  display: flex
  flex-direction: row
  align-items: center
  gap: $gap-sm-2
  align-self: stretch
  flex: none
  order: 2
  flex-grow: 0
  +res-lg-min
    justify-content: space-between

.myAccountAccountView
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: $gap-md

.separator
  +res-lg-min
    display: block

.editContainer
  margin: 15px 0 15px auto
  border-bottom: 1px solid $accent-default
  .label
    +font(16, 19.2, 600)
.svg
 width: 85%

.editButton
  padding: 0 !important
