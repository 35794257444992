@import styles/sass/abstracts

.container
  display: flex
  flex-direction: column
  align-items: center
  padding: 30px
  height: 100%
  +whiteBoxShadow
  +res-sm-max
    padding: 0
    box-shadow: none

.header
  display: flex
  flex-direction: column
  gap: $gap-lg-3
  margin-bottom: 40px
  text-align: center
  +res-sm-max
    display: none
  .title
    +typoTitleMd
  .description
    +typoDescriptionMd

.register
  width: 100%
  margin-bottom: 20px
  +font(16, 22, 600)
  +res-sm-max
    margin-bottom: 20px

.link
  margin-bottom: $gap-lg-3
  +res-sm-max
    margin-bottom: 0
