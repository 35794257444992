@import styles/sass/abstracts

//.datiFormCard
  //&:not(.datiFormCard--edit)
    //.content
    //  +res-md-min
    //    padding-right: 25%

.content
  position: relative

.editButton
  +res-md-min
    position: absolute
    top: 0
    right: 0
  +res-sm-max
    margin: 20px auto 0
    display: flex
    max-width: fit-content
  
