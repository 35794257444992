@import styles/sass/abstracts

.navMobile
  background: $white
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 20px 20px 0 20px
  gap: $gap-lg-1

.header
  display: flex
  flex-direction: row
  width: 100%
  .leftContent
    margin-right: auto
  .rightContent
    margin-left: auto
  .navMobileButton
    margin-left: auto
    cursor: pointer
    +res-lg-min
      display: none
    svg
      +style-icon(24px, currentColor)

.menuAccount
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: $gap-lg-1
  .loginButton
    display: flex
    flex-direction: row-reverse
    text-decoration: underline
    text-underline-offset: 1px
  .menuAccountTitle
    +font(20px, 110%, 700)
    color: $secondary-dark
  .item
    padding: 0 0 0 20px

.menuItems
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 0
  gap: $gap-sm-2
  .box
    width: 100%
  .icon
    +size-icon(40px)
  .title
    display: flex
    flex-direction: row
    align-items: center
    gap: $gap-sm-2
    +font(16, 22, 700)

.cardItem
  width: 100%  