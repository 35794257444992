@import styles/sass/abstracts

.formField
  width: 100%
  background-color: white
  padding: 20px !important
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%)

.containerOptins
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 10px
  >*
    flex: 49%
  +res-md-max
    >*
      flex: 100%

.optinBrandCard
  width: 100%
  background-color: white
  padding: 10px 16px 10px 10px
  border-radius: 4px
  display: flex
  justify-content: space-between
  align-items: center
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%)

  .optinNameImageContainer
    display: flex
    align-items: center

    img
      padding-right: 10px

    .name
      color: $primary-dark
      font-size: 16px
      padding-left: 10px

.container > *
  margin-top: 20px

.container > *:last-child
  margin-bottom: 20px

.buttonsDesktop
  +res-md-max
    display: none

.sticky
  background: white
  position: sticky
  bottom: 0
  height: 71px
  width: 100%
  box-shadow: 0px -6px 20px rgb(0 0 0 / 10%)
  +res-lg-min
    display: none

.buttons
  display: flex
  width: 100%
  justify-content: center
  +res-md-max
    flex-direction: row
    align-items: center
    padding: 10px 16px

.button
  margin: 0 auto
  +res-md-max
    width: 100%
