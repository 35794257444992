@import styles/sass/abstracts

.eventoCard
  display: flex
  flex-direction: row
  align-items: stretch
  +whiteBoxShadow
  +res-md-max
    max-width: 494px
    flex-direction: column
    gap: 0

.imageBox
  width: 494px
  height: 286px
  +res-md-max
    width: auto
    height: auto

.content
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  padding: 20px 50px
  flex: 1

  +res-md-max
    padding: 30px 20px

  > *
    &:not(:last-child)
      margin-bottom: 24px
      +res-md-max
        margin-bottom: 20px

  .title
    text-align: left
    +font(32, 35, 700)
    +res-md-max
      +font(24, 28, 700)
  .description
    +font(18, 22, 400)
    text-align: left
    +res-md-max
      +font(16, 22, 400)
