@import styles/sass/abstracts

.container
  justify-content: center
  text-align: center

  .icon
    width: 20%
    margin: auto
    svg
      +color-icon($success-check)

  .title
    margin: 15px 0
    line-height: 1.15

  .description
    margin: 15px 0

.button
  +res-md-max
    width: 100%

