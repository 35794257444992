@import 'styles/sass/abstracts'
  
.thankYouPage
  text-align: center
  +res-sm-max
    padding: 105px 0

.content
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px
  gap: $gap-sm-2
  +whiteBoxShadow
.icon
  svg
    display: block
    +style-icon(120px, $success-check)
    +res-sm-max
      +size-icon(80px)
.title
  +typoH3Serif
  +res-sm-max
    +typoH2Serif
.text
  +typoTextMd
  +res-sm-max
    +typoTextSm
.children
  +typoTextMd
  +res-sm-max
    +typoTextSm
.buttons
  display: flex
  align-items: center
  justify-content: center
  > *
    &:not(:last-child)
      margin-right: 24px