@import styles/sass/abstracts

.formLayout
    display: flex
    flex-direction: column
    gap: $gap-sm-2

.formField
    width: 100%

.formRadio
    display: flex
    flex-direction: column

.contactField
    @extend .formField
    display: flex
    flex-direction: row
    +res-sm-max
        flex-direction: column

.inputContact
    width: 275px

    &--phone
        margin-top: auto

    +res-sm-max
        width: 100%
        input
            width: 100%