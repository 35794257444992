@import styles/sass/abstracts

.label
  display: block
  +typoLabelMd
  font-weight: 900
  margin-bottom: 8px
  color: $primary-middle
.value
  display: block
  +typoTextMd
  font-weight: 700
  min-height: 140%
.underlined
  text-decoration: underline