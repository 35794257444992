@import styles/sass/abstracts

.nav
  display: flex
  justify-content: space-evenly
  color: $primary-default
  height: 100%
  +res-md-max
    display: none
  
.item
  display: block
  +typoTextSm
  font-weight: $fw-black
  text-transform: uppercase
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  +res-lg-max
    +typoTextXs
  &--active
    .itemContent
      border-bottom-color: currentColor

span.item
  cursor: default

.itemContent
  padding: 6px 2px 4px
  border-bottom: 2px solid transparent
  white-space: nowrap
  +transition(border-color .2s)

.dropdown
  position: absolute
  top: 100%
  left: 0
  width: 100%
  max-height: calc(100vh - $header-height)
  background: $white
  padding: 37px 0 50px
  overflow: auto
  +scrollbar-style
  box-shadow: $shadow-md
  display: none
  +res-md-max
    display: none
  &--open
    +res-lg-min
      display: block

.articoli
  display: flex
  > *
    &:not(:last-child)
      margin-right: 16px

.prodotti
  display: flex
  align-items: center
  justify-content: center
  > *
    flex: 1 0 0
    max-width: 164px
    &:not(:last-child)
      margin-right: 16px

.prodottiItem
  &--final
    flex: 1
    max-width: none
    > *
      text-align: left
      max-width: 180px

.boxes
  display: flex
  > *
    &:not(:last-child)
      margin-right: 16px

.dropdownLayout
  +res-lg-min
    display: flex
    margin: 0 #{$wcont-padding * -1}
    
.col
  +res-lg-min
    width: 25%
    padding: 0 $wcont-padding
    &--2
      width: 50%
    &--3
      width: 75%
    &--4
      width: 100%

.key
  &--calcolo_settimane
    +res-lg-min
      order: -1
 
.dropdownMenu
  &--2levels
    +res-lg-min
      display: flex
      align-items: flex-start
      margin: 0 #{$wcont-padding * -1}
      > *
        padding: 0 $wcont-padding
        &:first-child
          width: 33.33%
        &:last-child
          width: 66.66%
        
.dropdownMenuBack
  +res-lg-min
    display: none
  +res-md-max
    margin-bottom: 10px

.dropdownMenuLevel1
  > *
    &:not(:last-child)
      margin-bottom: 10px

.dropdownMenuLevel1Item
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  +res-lg-min
    padding: 17px 20px
    +typoTextSm
    font-weight: $fw-bold
    background: $white
    border-radius: 16px
    border: 1px solid $neutral-4
    cursor: pointer
  &--active, &:hover
    background: $neutral-1
    border-color: $neutral-1
  +res-md-max


.dropdownMenuItemArrow
  svg
    +style-icon(20px, currentColor)


.dropdownMenuLevel2
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  margin-left: #{$wcont-padding * -.5}
  margin-right: #{$wcont-padding * -.5}
  > *
    width: 33.33%
    padding: 0 #{$wcont-padding * .5}
    &:not(:last-child)
      margin-bottom: 10px
  
.dropdownMenuLevel2Item
  &--large
    width: 100%

.dropdownMenuLevel2ItemLink
  display: block
  padding: 18px 20px
  +typoTextSm
  font-weight: $fw-bold
  background: $neutral-1
  border-radius: 20px
  cursor: pointer

.dropdownMenuLevel2FinalLink
  width: 100%
  text-align: left
