@import styles/sass/abstracts

.lgField
    width: 100%

.checkboxRegister
    @extend .lgField
    +res-sm-max
        margin-bottom: 16px

.separator
    height: 1px
    margin: 20px 0
    display: flex

.privacyTitle
    +typoDescriptionSm
    margin-bottom: 13px

.submit
    @extend .lgField
    margin-top: 40px
    +res-sm-max
        margin-top: 16px
        max-width: 100%!important

.fieldMarketing
  > *
    flex-direction: column

.preferenzeTrigger
  align-self: flex-start
  margin-left: 10px
  text-decoration: underline

.preferenzeContainer
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 10px
  padding: 20px 10px
  >*
    flex: 49%
  +res-md-max
    >*
      flex: 100%

.optinBrandCard
  width: 100%
  background-color: white
  padding: 0 16px 0 10px
  border-radius: 4px
  display: flex
  justify-content: space-between
  align-items: center
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%)

  .optinNameImageContainer
    display: flex
    align-items: center

    img
      padding-right: 10px

    .name
      color: $primary-dark
      font-size: 16px
      padding-left: 10px
