@import styles/sass/abstracts

.articoloCard
  display: block
  max-width: 357px
  text-align: left // a volte è in un contesto con text-align: center

  .imageBox
    position: relative
    height: 240px
    border-radius: $radius-sm
    overflow: hidden
    box-shadow: $shadow-md
    &:hover
      > *:not(.tags) 
        +scale(1.05)
    +res-sm-max
      height: 164px
    > *:not(.tags)
      height: 100%!important
      transition: transform .3s 
    
  .tags
    position: absolute
    top: 10px
    left: 10px
    height: auto!important
    display: flex
    flex-wrap: wrap
    +res-sm-max
      top: 6px
      left: 6px
    > *
      margin-bottom: 6px
      &:not(:last-child)
        margin-right: 6px

  .content
    padding: 12px $wcont-padding
    +res-sm-max
      padding-left: 2px
      padding-right: 2px

  .title
    +typoTextLg
    display: block
    font-weight: $fw-black
    margin-bottom: 8px
    +res-sm-max
      +typoTextMd

  .description
    +typoTextMd
    +transition(color .2s)
    margin-bottom: 16px
    +multiline-ellipsis(2, 22px)
    +res-sm-max
      +typoTextSm

  &--sm
    max-width: 272px
    .imageBox
      height: 190px
  
  &--big
    max-width: 740px
    .imageBox
      height: 497px
      +res-sm-max
        height: 240px
    .title
      +typoTextLg
    .tags
      +res-sm-max
        top: 10px
        left: 10px

  &--horizontal
    +res-lg-min
      display: flex
      align-items: center
      max-width: none
      &:not(:last-child)
        margin-bottom: 20px
      .content
        padding: 0 $wcont-padding
      .imageBox
        height: 180px
        max-width: 214px
      .tags
        display: none

  &--default--horizontal
    display: flex
    align-items: center
    max-width: none
    .content
      background-color: $neutral-1
      border-radius: 8px
    .imageBox
      display: none
    .tags
      display: none

  &--shadow
    .imageBox
      box-shadow: $shadow-md

.imageLink
  display: block

.containerNumeroSettimana
  position: relative
  display: flex
  justify-content: center
  align-items: center
.numeroSettimana
  position: relative
  display: flex
  justify-content: center
  align-items: center
  +typoBase(10vw, normal, 0.01em, $fw-bold, $font-secondary)
  color: $primary-default
  opacity: .1
  +res-sm-max
    +typoBase(20vw, normal, 0.01em, $fw-bold, $font-secondary)



