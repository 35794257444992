@import styles/sass/abstracts

.buttons
  display: flex
  width: 100%
  justify-content: center

.button
  margin: 20px auto 0 auto
  +res-md-max
    width: 100%

.formField
  width: 100%
  background-color: white
  padding: 20px !important

.optinBrandCard
  background-color: white
  display: flex
  justify-content: space-between
  align-items: center
  margin: 0 auto
  padding: 10px 16px 10px 10px
  border-radius: 4px
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)
  width: 50%
  +res-md-max
    width: 100%

  .optinNameImageContainer
    display: flex
    align-items: center

    img
      padding-right: 10px

    .name
      color: $primary-dark
      font-size: 16px
      padding-left: 10px
